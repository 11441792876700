import { useMemo, useState } from 'react';
import { useBoolean, useMemoizedFn } from 'ahooks';
import { Input, Radio, Select } from '@formily/antd-v5';
import { Button, Card, Modal, message } from 'antd';
import { AiOutlinePlus, AiOutlineDelete, AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { deleteDict, getDictList, refreshDict } from 'src/api/clients/system';
import { IDictItem, IGetDictListReq } from 'src/api/types/system';

import { QueryTable, QueryTableColumnProps } from 'src/components/QueryTable';
import { schema, columns } from './schema';
import { DictModal } from './dict-modal';
import { DictDataDrawer } from './dict-data-drawer';

const QueryTablDictsManage = 'SystemDictsManageTableName';

export default function SystemDictsManage() {
  const [open, { setTrue, setFalse }] = useBoolean(false);
  const { t } = useTranslation();
  const [openData, { setTrue: setDataTrue, setFalse: setDataFalse }] = useBoolean(false);
  const [loading, setLoading] = useState(false);
  const [currentDict, setCurrentDict] = useState<IDictItem>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const getRolesTableList = useMemoizedFn(async (params: IGetDictListReq) => {
    const result = await getDictList(params);
    return {
      data: result.rows,
      total: result.count,
    };
  });

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onDeleteDictByIds = useMemoizedFn(async (ids: number[]) => {
    Modal.confirm({
      title: '注意',
      content: `删除后无法恢复，确定删除指定字典吗？`,
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        await deleteDict(ids);
        message.success('删除成功');
        onRefresh();
      },
    });
  });

  const onRefresh = useMemoizedFn((payload: Record<string, any> = {}) => {
    QueryTable.ProviderGlobalDispatch(
      {
        type: QueryTable.ActionEnum.REQUEST_START,
        payload,
      },
      QueryTablDictsManage,
    );
  });

  const refresh = useMemoizedFn(async () => {
    setLoading(true);
    try {
      await refreshDict();
      message.success('刷新成功');
    } finally {
      setLoading(false);
    }
  });

  const totalColumn = useMemo(() => {
    return [
      ...columns,
      {
        title: '操作',
        fixed: 'right',
        width: 150,
        renderType: 'Operation',
        renderProps: (col: IDictItem) => {
          return {
            buttons: [
              {
                label: '编辑',
                onClick: () => {
                  setCurrentDict(col);
                  setTrue();
                },
              },
              {
                label: '数据管理',
                onClick: async () => {
                  setDataTrue();
                  setCurrentDict(col);
                },
              },
              {
                label: '删除',
                onClick: async () => {
                  await onDeleteDictByIds([col.dictId]);
                },
              },
            ],
          };
        },
      },
    ] as QueryTableColumnProps;
  }, [columns]);

  return (
    <Card className="m-4 rounded-md">
      <div className="text-base font-semibold mb-4">{t('menu.dict')}</div>
      <QueryTable.Provider name={QueryTablDictsManage} requestFn={getRolesTableList}>
        <QueryTable.Filter schema={schema} components={{ Input, Select, Radio }} />
        <QueryTable.Table
          columns={totalColumn}
          rowKey="dictId"
          scroll={{ x: 1200 }}
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
          }}
          tabsNode={
            <>
              <Button icon={<AiOutlinePlus />} type="primary" onClick={setTrue}>
                新增字典
              </Button>
              <Button
                loading={loading}
                icon={<AiOutlineLoading3Quarters />}
                variant="outlined"
                onClick={refresh}>
                刷新缓存
              </Button>
            </>
          }
          extraRightTool={
            <Button
              disabled={!selectedRowKeys.length}
              color="danger"
              icon={<AiOutlineDelete />}
              onClick={() => {
                onDeleteDictByIds(selectedRowKeys as number[]);
              }}
              variant="outlined">
              批量删除
            </Button>
          }
        />
      </QueryTable.Provider>
      <DictModal
        open={open}
        onOk={onRefresh}
        data={currentDict}
        onCancel={() => {
          setFalse();
          setCurrentDict(undefined);
        }}
      />
      <DictDataDrawer
        width={'100vw'}
        open={openData}
        onClose={setDataFalse}
        dictType={currentDict?.dictType}
        dictName={currentDict?.dictName}
      />
    </Card>
  );
}
